import CloseIcon from '@mui/icons-material/Close'
import { Slide } from '@mui/material'
import Modal from '@mui/material/Modal'
import React, { useContext, useEffect, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { FormattedMessage } from 'react-intl'
import styles from './UpSellDesktopModal.module.scss'
import UpSellContext from '../../../../context/UpSellContext'
import useCart from '../../../../hooks/cart/cart'
import { THEMED_QUERY_SELECTOR } from '../../../../interfaces/AppInterface'
import { CatalogListItemInterface } from '../../../../interfaces/CatalogInterface'
import CartItems from '../../../Cart/CartItems/CartItems'
import MiniCartTotal from '../../../MiniCart/MiniCartTotal/MiniCartTotal'
import UpSellProducts from '../../UpSellProducts/UpSellProducts'

interface Props {
  promotionProducts?: CatalogListItemInterface[]
}

const UpSellDesktopModal: React.FC<Props> = (props: Props) => {
  const { promotionProducts } = props
  const upSell = useContext(UpSellContext)
  const { cart } = useCart()

  const [modalContainer, setModalContainer] = useState<Element | null>(null)

  useEffect(() => {
    setModalContainer(document.querySelector(THEMED_QUERY_SELECTOR))
  }, [])

  return (
    <Modal
      open={upSell.showModal}
      onClose={upSell.handleClose}
      container={modalContainer}
      disableScrollLock={false}
      slotProps={{ backdrop: { className: styles['up-sell-desktop-modal__backdrop'] } }}
    >
      <div className={styles['up-sell-desktop-modal__container']}>
        <div className={styles['up-sell-desktop-modal__up-sell-products']}>
          <Slide direction="down" in={promotionProducts && promotionProducts.length > 0} mountOnEnter unmountOnExit timeout={500}>
            <div className={styles['up-sell-products__content']}>
              {promotionProducts && promotionProducts.length > 0 && <UpSellProducts products={promotionProducts} />}
            </div>
          </Slide>
        </div>
        <Slide direction="left" in={upSell.showModal} mountOnEnter unmountOnExit timeout={500}>
          <div className={styles['up-sell-desktop-modal__cart__container']}>
            <div className={styles['up-sell-desktop-modal__cart']}>
              <div className={styles['cart__header']}>
                <FormattedMessage id="UpSell.YourCart" description="UpSell.YourCart" defaultMessage="Your Bag" />
                <div className={styles['header__close-button__container']}>
                  <button type="button" className={styles['cart__header__close-button']} onClick={upSell.handleClose}>
                    <CloseIcon />
                  </button>
                </div>
              </div>
              <div className={styles['cart__content']}>
                <Scrollbars hideTracksWhenNotNeeded={false}>
                  <CartItems cart={cart} gifts={cart.gifts} className={styles['cart__content__list']} compactLayout />
                </Scrollbars>
              </div>
              <div className={styles['up-sell-desktop-modal__footer']}>
                <MiniCartTotal
                  onButtonClick={upSell.handleClose}
                  onClose={upSell.handleClose}
                  continueShoppingBtn
                  myBagBtn
                  classes={{
                    root: styles['up-sell-desktop-modal__total'],
                  }}
                />
              </div>
            </div>
          </div>
        </Slide>
      </div>
    </Modal>
  )
}

export default UpSellDesktopModal
