import { BlogTagInterface } from './BlogInterface'
import { ChannelInterface, ChannelSiteInterface } from './ChannelInterface'
import { CurrencyMapInterface } from './CurrencyInterface'
import { DescriptionInterface } from './DescriptionInterface'
import { LanguageInterface, LanguageMapInterface } from './LanguageInterface'
import UserInterface from './UserInterface'

export const THEMED_QUERY_SELECTOR = '#app > .themed'

export interface AppInterface {
  hostUrl: string
  baseUrl: string
  requestUrl: string
  channels: ChannelInterface[]
  languages: LanguageMapInterface
  userLanguage: LanguageInterface
  currencies: CurrencyMapInterface
  deliveryPost: DescriptionInterface
  isAuth: boolean
  user: UserInterface | null
  quantityBuffer: number
  newsletterOptions: []
  currency: string
  shippingMethods: []
  paymentMethods: []
  loyaltyPointsConvertRate: number
  allChannels: ChannelInterface[]
  blogTags: BlogTagInterface[]
  ipCountry: string | null
  recommendedChannel: ChannelSiteInterface | null
}

export default AppInterface
